<template>
	<div v-if="canShowNotes" class="case-notes">
		<div class="tsa-admin-header">
			<span class="page-name">{{ sectionTitle }}</span>
			<case-info-controls :case-entry="caseEntry" button-type="note" @updated="updateCase" />
		</div>
		<div v-if="caseEntry" class="case-notes-list">
			<div v-for="note in caseEntry.notes" :key="'note_' + note.id" class="case-note" :class="{swap:isFromMe(note)}">
				<div class="case-note-bylines">
					<company-logo :company-name="note.company.name" :company-url="note.company.url" />
					<div class="case-note-byline-value">{{ note.user.full_name }}</div>
					<div class="case-note-byline-value">{{ displayDate(note.created_at) }}</div>
				</div>
				<div class="case-note-main">
					<div v-if="note.summary && note.summary !== ''" class="case-note-content">
						{{ note.summary }}
					</div>
					<div v-if="note.description" class="case-note-content" v-html="nl2br(note.description)"></div>
				</div>
			</div>
			<div v-if="thereAreNoNotes" class="uk-text-center">
				<strong>There are no collaborations for this case.</strong>
			</div>
		</div>
	</div>
	<div v-else-if="loadingCase">
		<div class="tsa-admin-header">
			<span class="page-name">{{ sectionTitle }}</span>
		</div>
	</div>
	<div v-else>
		<div class="tsa-admin-header">
			<span class="page-name">{{ sectionTitle }}</span>
			<case-info-controls :case-entry="caseEntry" button-type="note" @updated="updateCase" />
		</div>
		<CompanyChangeRequest
			:is_open="show_request_window"
			@close="onCloseChangeRequest"
			request_type="Set up Single Sign-on" />
		<div class="uk-text-center">
			<strong><a href="https://www.tsanet.org/tsanet-connect-single-sign-on/" target="_blank">Single Sign-On</a> is required for Collaboration Feed.</strong>
			<button v-if="canMakeRequest" class="tsa-btn tsa-btn-secondary uk-margin-small-left" @click.prevent="contactTSA">Contact TSANet</button>
		</div>
	</div>
</template>

<script>
import Filters from "@/Mixins/Filters.vue";
import {mapState} from "vuex";
import CaseInfoControls from "@/components/CaseDetails/CaseInfoControls.vue";
import CompanyLogo from "../Layout/CompanyLogo.vue";
import CompanyChangeRequest from '@/components/Admin/CompanyChangeRequest.vue';
export default {
	name: "CaseNotes",
	
	props: {
		caseEntry: {
			type: Object,
			default: () => {}
		}
	},
	components: {
		CaseInfoControls,
		CompanyLogo,
		CompanyChangeRequest
	},
	mixins: [Filters],
	data() {
		return {
			show_request_window: false,
			sectionTitle: "Collaboration Feed",
		}
	},
	mounted() {
		
	},
	methods: {
		updateCase(){
			this.$emit("updated");
		},
		isFromMe(note){
			return note.company.id == this.company.id;
		},
		contactTSA(request_type){
			this.request_type = request_type;
			this.show_request_window = true;
		},
		onCloseChangeRequest(){
			this.show_request_window = false;
		},
	},
	computed:{
		...mapState({
			user: state => state.user,
			company: state => state.company
		}),
		canShowNotes(){
			if(this.company && this.company.allow_notes){
				return true;
			}
			return false;
		},
		thereAreNoNotes(){
			return this.caseEntry.notes && this.caseEntry.notes.length == 0;
		},
		canMakeRequest(){
			return this.user.role == "business_manager" || this.user.role == "program_manager";
		},
		loadingCase(){
			return !this.company || !this.caseEntry || !this.caseEntry.id;
		}
	},
	watch: {
		caseEntry(){
			
		}
	},
	emits:["updated"]
}
</script>