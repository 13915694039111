<template>
<div></div>
</template>

<script>

import GlobalStorage from "../Classes/GlobalStorage";
import {mapState} from "vuex";

export default {
	name: "RegistrationSaver",

	props: {},
	components: {},
	data() {
		return {}
	},
	mounted() {
		
	},
	methods: {
		autoSaveRestoreRegistrationData(){
			if(this.partner_group.id){
				this.saveRegistrationData();
			} else {
				this.loadRegistrationData();
			}
		},
		saveRegistrationData(){
			GlobalStorage.save("tsa_onboarding_partner_group", this.partner_group);
			GlobalStorage.save("tsa_onboarding_company", this.company);
			GlobalStorage.save("tsa_onboarding_countries", this.countries);
		},
		loadRegistrationData(){
			let testPartner = GlobalStorage.load("tsa_onboarding_partner_group");
			let testCompany = GlobalStorage.load("tsa_onboarding_company");
			let testCountries = GlobalStorage.load("tsa_onboarding_countries");
			if(testPartner && testCompany && testPartner.id && testCompany.id){
				this.$store.dispatch('partnerGroup',{
					company: testCompany,
					group: testPartner,
					countries: testCountries
				});
				
			}
			this.restoreRegistrationSession();
		},
		clearRegistrationData(){
			GlobalStorage.save("tsa_onboarding_partner_group", null);
			GlobalStorage.save("tsa_onboarding_company", null);
			GlobalStorage.save("tsa_onboarding_countries", null);
			GlobalStorage.save("tsa_onboarding_session", null);
		},
		saveRegistrationSession(sessionData){
			GlobalStorage.save("tsa_onboarding_session", sessionData);
		},
		restoreRegistrationSession(){
			if(GlobalStorage.has("tsa_onboarding_session")){
				let sessionData = GlobalStorage.load("tsa_onboarding_session");
				this.$store.dispatch('onboardingSession',sessionData);
			}
		}
	},
	computed:{
		...mapState({
			partner_group: state => state.partner_group,
			company: state => state.company,
			countries: state => state.countries,
			session_token: state => state.onboarding_session_token
		})
	}
}
</script>