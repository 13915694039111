<template>
	<div>
		<div class="tsa-section-title">Case Information:</div>

		<template v-if="case_entry.case_type == 'manager' && managerRequest">
			<div class="tsa-data">
				<!-- company -->
				<div class="tsa-data-label">Company:</div>
				<div class="tsa-data-value">{{ case_entry.company.name }}</div>
			</div>
			<div class="tsa-data">
				<!-- case number -->
				<div class="tsa-data-label">{{ case_entry.company.name }} Reference:</div>
				<div class="tsa-data-value">{{ managerRequest.internal_reference }}</div>
			</div>
			<div v-if="managerRequest.vendor_reference" class="tsa-data">
				<!-- case number -->
				<div class="tsa-data-label">Case # with {{ case_entry.receiver.name }}:</div>
				<div class="tsa-data-value">{{ managerRequest.vendor_reference }}</div>
			</div>
			<div class="tsa-data">
				<div class="tsa-data-label">Date:</div>
				<div class="tsa-data-value">{{ dateSubmitted }}</div>
			</div>
			<div class="tsa-data">
				<!-- submitted by -->
				<div class="tsa-data-label">Submitted by:</div>
				<div class="tsa-data-value">
					{{ case_entry.engineer.first_name }} {{ case_entry.engineer.last_name }}<br>
					<a :href="`mailto:${case_entry.engineer.email}`">{{ case_entry.engineer.email }}</a>
				</div>
			</div>
			<div class="tsa-data">
				<!-- summary -->
				<div class="tsa-data-label">Business Impact:</div>
				<div class="tsa-data-value" v-html="caseBusinessImpact"></div>
			</div>
			<div class="tsa-data">
				<!-- description -->
				<div class="tsa-data-label">Description</div>
				<div class="tsa-data-value tsa-break-word" v-html="caseDescription"></div>
			</div>
			<div class="tsa-data">
				<!-- priority -->
				<div class="tsa-data-label">Priority</div>
				<div class="tsa-data-value">{{ casePriority }} - P{{ casePriorityNumber }}</div>
			</div>
			<div
				v-for="(row,index) in case_entry.customer_data"
				:key="index">
				<div class="tsa-data" v-if="row.section == 'problem_section'">
					<div class="tsa-data-label">{{ row.field }}</div>
					<div class="tsa-data-value">{{ row.value }}</div>
				</div>
			</div>
		</template>
		<template v-else>
			<div class="tsa-data">
				<!-- company -->
				<div class="tsa-data-label">Company:</div>
				<div class="tsa-data-value">{{ case_entry.company.name }}</div>
			</div>
			<div class="tsa-data">
				<!-- case number -->
				<div class="tsa-data-label">{{ case_entry.company.name }} Case #:</div>
				<div class="tsa-data-value">{{ case_entry.internal_case_number }}</div>
			</div>
			<div class="tsa-data">
				<div class="tsa-data-label">Date:</div>
				<div class="tsa-data-value">{{ dateSubmitted }}</div>
			</div>
			<div class="tsa-data">
				<!-- submitted by -->
				<div class="tsa-data-label">Submitted by:</div>
				<div class="tsa-data-value">
					{{ case_entry.engineer.first_name }} {{ case_entry.engineer.last_name }}<br>
					<a :href="`mailto:${case_entry.engineer.email}`">{{ case_entry.engineer.email }}</a>
				</div>
			</div>
			<div class="tsa-data">
				<!-- summary -->
				<div class="tsa-data-label">Summary</div>
				<div class="tsa-data-value">{{ case_entry.summary }}</div>
			</div>
			<div class="tsa-data">
				<!-- description -->
				<div class="tsa-data-label">Description</div>
				<div class="tsa-data-value tsa-break-word" v-html="caseDescription"></div>
			</div>
			<div class="tsa-data">
				<!-- priority -->
				<div class="tsa-data-label">Priority</div>
				<div class="tsa-data-value">{{ casePriority }} - P{{ casePriorityNumber }}</div>
			</div>
			<div
				v-for="(row,index) in case_entry.customer_data"
				:key="index">
				<div class="tsa-data" v-if="row.section == 'problem_section'">
					<div class="tsa-data-label">{{ row.field }}</div>
					<div class="tsa-data-value">{{ row.value }}</div>
				</div>
			</div>
		</template>

		<!--div class="tsa-section-title">Customer Data:</div>
		<div class="tsa-data"
			v-for="(row,index) in case_entry.customer_data"
			:key="index">
			<div class="tsa-data-label">{{ row.field }}</div>
			<div class="tsa-data-value">{{ row.value }}</div>
		</div-->
	</div>
</template>

<script>
// import any required components

// export component
export default {
	// properties that the component will receive from parent
	props: {
		case_entry: {
			type: Object,
			default: () => {}
		}
	},
	// components to use in template
	components:{

	},
	// component data
	data() {
		return {};
	},
	// component was mounted
	mounted(){

	},
	// any methods needed for this component
	methods: {
	},
	// computed properties
	computed:{
		casePriority(){
			if(this.case_entry.priority == undefined) return "";
			return this.case_entry.priority.ucfirst();
		},
		casePriorityNumber(){
			if(this.case_entry.priority == undefined) return "";
			let number = 0
			switch(this.case_entry.priority){
				case "low":
					number = 3;
				break;
				case "medium":
					number = 2;
				break;
				case "high":
					number =  1;
				break;
			}
			return number;
		},
		dateSubmitted(){
			if(this.case_entry.created_at == undefined) return "";
			return this.$moment(this.case_entry.created_at)/*.local()*/.format("MM/DD/YY hh:mm a");
		},
		caseDescription(){
			if(this.case_entry.description == undefined) return "";
			return this.case_entry.description.nl2br();
		},
		caseBusinessImpact(){
			if(this.managerRequest && this.managerRequest.business_impact){
				return this.managerRequest.business_impact.nl2br();
			}
			return "";
		},
		managerRequest(){
			if(this.case_entry.manager_requests == undefined) {
				return null;
			}
			if(this.case_entry.manager_requests.length > 0){
				return this.case_entry.manager_requests[0];
			}
			return null;
		}
	},
	// property watchers
	watch:{
	}
}
</script>