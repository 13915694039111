<template>
	<div>
		<div v-if="caseResponse">
			<div class="tsa-info-title">
				<strong>Information Requested:</strong> {{ $moment(caseRequest.created_at).format("MM/DD/YY hh:mm a") }}
				<case-info-controls :case-entry="caseEntry" button-type="engineer" @updated="updateCase" />
			</div>
			<div class="form-row data-row uk-margin-small" v-html="responseInformation"></div>
			<div class="utc-note uk-margin-medium">
				<span>Waiting for Member Response</span>
			</div>
			<router-link v-if="company.id == caseEntry.receiver.id" :to="{name:'case-response',params:{token:caseEntry.token}}" class="tsa-btn">Respond to Case</router-link>
		</div>
		<div v-if="caseRequest && !caseResponse">
			<div class="tsa-info-title">
				<strong>Information Requested:</strong> {{ $moment(caseRequest.created_at).format("MM/DD/YY hh:mm a") }}
				<case-info-controls :case-entry="caseEntry" button-type="engineer" @updated="updateCase" />
			</div>
			<div class="form-row data-row uk-margin-small" v-html="moreInformation"></div>
			<div v-if="company.id == caseEntry.receiver.id" class="utc-note uk-margin-medium">
				<span>Waiting for Sender Response</span>
			</div>
			<div v-else class="utc-note uk-margin-medium">
				<span>Waiting for Member Response</span>
			</div>
			<router-link v-if="company.id == caseEntry.company.id" :to="{name:'case-provide-information',params:{token:caseEntry.token}}" class="tsa-btn">Respond to Case</router-link>
		</div>

	</div>
</template>

<script>
import {mapState} from "vuex";
import CaseInfoControls from "@/components/CaseDetails/CaseInfoControls.vue";

export default {
	name: "CaseMoreInfo",
	components: {
		CaseInfoControls
	},
	props: {
		caseEntry: {
			type: Object,
			default: () => {}
		}
	},
	data() {
		return {
			caseRequest: null,
			caseResponse: null,
		}
	},
	mounted() {
		if(this.caseEntry){
			this.caseRequest = this.findRequest();
			this.caseResponse = this.findReply();
		}
	},
	methods: {
		findRequest(){
			if(this.caseEntry.responses){
				for(let i = 0; this.caseEntry.responses.length > i;++i){
					if(this.caseEntry.responses[i].type == "information-request"){
						return this.caseEntry.responses[i];
					}
				}
			}
			return null;
		},
		findReply(){
			if(this.caseEntry.responses){
				for(let i = 0; this.caseEntry.responses.length > i;++i){
					if(this.caseEntry.responses[i].type == "information-response"){
						return this.caseEntry.responses[i];
					}
				}
			}
			return null;
		},
		updateCase(){
			this.$emit("updated");
		}
	},
	computed:{
		/**
		 * convert \n to <br>
		 * @return {String} [description]
		 */
		responseInformation(){
			if(this.caseResponse.next_steps == undefined) return "";
			return this.caseResponse.next_steps.nl2br();
		},
		/**
		 * convert \n to <br>
		 * @return {String} [description]
		 */
		moreInformation(){
			if(this.caseRequest.next_steps == undefined) return "";
			return this.caseRequest.next_steps.nl2br();
		},
		...mapState({
			user: state => state.user,
			company: state => state.company
		})
	}
}
</script>